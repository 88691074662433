<template>
  <ValidationObserver
    ref="observer"
    class="search-by-provider"
    column="xs-12 s-12">
    <flash-notification v-if="serviceErrorOccurred">
      {{section.failedToFetchNetworkOptionsMessage}}
    </flash-notification>
    <form
      v-else
      :grid="hideNetworkDropdown ? 'row wrap center justify-center': 'row wrap bottom justify-center'"
      role="form"
      @submit.prevent="submit">
      <honey-pot aria-hidden="true" @honeyPotUniqueId="honeyPotUniqueId = $event" />
      <!--Search by Doctor Name -->
      <div
        column="xs-12 s-12 l-8"
        class="form-column"
        :class="{'auth-user': hideNetworkDropdown}">
        <div
          v-if="searchByDoctorName"
          grid="row wrap bottom"
        >
          <div
            :column="hideNetworkDropdown ? 'xs-12 s-12 m-6' : 'xs-12 s-12'"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="provider"
              v-model="provider"
              :label="section.doctorNameLabel"
              :placeholder="section.doctorNamePlaceholderText"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs zip-column">
            <form-input
              id="zip"
              v-model="zipValue"
              :label="section.zipCodeLabel"
              :validations="gettingCoords ? '' : 'required|regex:^[0-9]{5}(-[0-9]{4})?$'"
              :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
              :hide-validation-message="coordsRequested"
              :validation-message="section.zipCodeValidationMessage"
              @input="handleZipInput" />
          </div>
          <div
            v-if="!hideNetworkDropdown" 
            column="xs-12 s-12 m-6" 
            class="_mbs network-column">
            <form-multi-select
              id="network"
              v-model="networkValue"
              :label="section.networkSelectLabel"
              :placeholder="section.networkSelectPlaceholderText"
              :validation-message="section.networkSelectValidationMessage"
              :multiple="false"
              :allow-empty="false"
              :options="networks"
              :searchable="true"
              :searchStart="true"
              :clear-on-select="true"
              @update:modelValue="handleNetworkInput"/>
          </div>
          <div>
            <a
              v-if="section.showSearchByAddress"
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleSearchByDoctorAddressClick()"
              @keyup.enter="handleSearchByDoctorAddressClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchByAddressLinkText }}
            </a>
            <a
              v-if="section.showSearchByPhone"
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleSearchByDoctorPhoneNumberClick()"
              @keyup.enter="handleSearchByDoctorPhoneNumberClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchByPhoneLinkText }}
            </a>
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorClick()"
              @keyup.enter="handleDoctorClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchNpiLinkText }}
            </a>
          </div>
        </div>
        <!--Search by NPI or Doctor License-->
        <div
          v-if="searchByDoctorLicense"
          grid="row wrap bottom"
        >
          <div
            column="xs-12 s-12 m-6"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="npiNumber"
              v-model="npiNumber"
              :validations="npiValidation"
              :validation-message="npiValidationMessage"
              :label="section.npiLabel"
              :maxLength="npimaxlength"
              :disabled="uniqueDisabledCheck('npiNumber')"
              @input="handleZipInput" />
            <div>
              <h5
                class="moon"
                style="margin-bottom:-20px;"
              >
                <strong aria-hidden="true">OR</strong>
              </h5>
            </div>
          </div>
          <div column="xs-12 s-12 m-6" />
          <div
            column="xs-12 s-12 m-6"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="doctorLicense"
              v-model="doctorLicense"
              :label="section.doctorLicenseLabel"
              :validations="doctorlicenseValidation"         
              :maxLength="licensemaxlength"
              :validation-message="doctorLicenseValidationMessage"
              :disabled="uniqueDisabledCheck('doctorLicense')"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs zip-column"
            style="margin-bottom:32px;"
          >
            <form-input
              id="zip"
              v-model="zipValue"
              :label="section.zipCodeLabel"
              :validations="gettingCoords ? '' : 'required|regex:^[0-9]{5}(-[0-9]{4})?$'"
              :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
              :hide-validation-message="coordsRequested"
              :validation-message="section.zipCodeValidationMessage"
              @input="handleZipInput" />
          </div>
          <div column="xs-12 s-12 m-6" >
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorNameClick()"
              @keyup.enter="handleDoctorNameClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchDoctorNameLinkText }}
            </a>
            <a
              v-if="section.showSearchByAddress"
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleSearchByDoctorAddressClick()"
              @keyup.enter="handleSearchByDoctorAddressClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchByAddressLinkText }}
            </a>
            <a
              v-if="section.showSearchByPhone"
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleSearchByDoctorPhoneNumberClick()"
              @keyup.enter="handleSearchByDoctorPhoneNumberClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchByPhoneLinkText }}
            </a>
          </div>
        </div>
      </div>
      <!--Search by Doctor Phone Number -->
      <div
        v-if="searchByDoctorPhoneNumber"
        column="xs-12 s-12 l-8"
        class="form-column"
      >
        <div
          grid="row wrap bottom"
        >
          <div
            :column="'xs-12 s-12'"
            class="_mbs">
            <form-input
              id="doctorPhoneNumber"
              v-model="doctorPhoneNumber"
              :validations="doctorPhoneNumberValidation"
              :mask="doctorPhoneNumberMask"
              :label="section.doctorPhoneNumberLabel"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs">
            <form-input
              id="zip"
              v-model="zipValue"
              :label="section.zipCodeLabel"
              :validations="gettingCoords ? '' : 'required|regex:^[0-9]{5}(-[0-9]{4})?$'"
              :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
              :hide-validation-message="coordsRequested"
              :validation-message="section.zipCodeValidationMessage"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs" />
          <div>
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorNameClick()"
              @keyup.enter="handleDoctorNameClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchDoctorNameLinkText }}
            </a>
            <a
              v-if="section.showSearchByAddress"
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleSearchByDoctorAddressClick()"
              @keyup.enter="handleSearchByDoctorAddressClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchByAddressLinkText }}
            </a>
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorClick()"
              @keyup.enter="handleDoctorClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchNpiLinkText }}
            </a>
          </div>
        </div>
        <div
          v-if="searchByDoctorLicense"
          grid="row wrap bottom"
        >
          <div
            column="xs-12 s-12 m-6"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="npiNumber"
              v-model="npiNumber"
              :validations="npiValidation"
              :validation-message="npiValidationMessage"
              :label="section.npiLabel"
              :maxLength="npimaxlength"
              :disabled="uniqueDisabledCheck('npiNumber')"
              @input="handleZipInput" />
            <div>
              <h5
                class="moon"
                style="margin-bottom:-20px;"
              >
                <strong>OR</strong>
              </h5>
            </div>
          </div>
          <div column="xs-12 s-12 m-6" />
          <div
            column="xs-12 s-12 m-6"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="doctorLicense"
              v-model="doctorLicense"
              :label="section.doctorLicenseLabel"
              :validations="doctorlicenseValidation"         
              :maxLength="licensemaxlength"
              :validation-message="doctorLicenseValidationMessage"
              :disabled="uniqueDisabledCheck('doctorLicense')"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs zip-column"
            style="margin-bottom:32px;"
          >
            <form-input
              id="zip"
              v-model="zipValue"
              :label="section.zipCodeLabel"
              :validations="gettingCoords ? '' : 'required|regex:^[0-9]{5}(-[0-9]{4})?$'"
              :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
              :hide-validation-message="coordsRequested"
              :validation-message="section.zipCodeValidationMessage"
              @input="handleZipInput" />
          </div>
          <div column="xs-12 s-12 m-6" >
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorNameClick()"
              @keyup.enter="handleDoctorNameClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchDoctorNameLinkText }}
            </a>
          </div>
        </div>
      </div>
      <!--Search by Doctor Street Address -->
      <div
        v-if="searchByDoctorAddress"
        column="xs-12 s-12 l-8"
        class="form-column"
      >
        <div
          grid="row wrap bottom"
        >
          <div
            :column="'xs-12 s-12'"
            class="_mbs">
            <label>Please Note: {{ section.stateOrZipRequiredMessage }}</label>
            <form-input
              id="doctorStreetaddress"
              v-model="doctorStreetaddress"
              :validations="doctorAddressValidation"
              :validation-message="doctorAddressValidationMessage"
              :label="section.streetAddressLabel"
            />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs">
            <form-input
              id="doctorCity"
              v-model="doctorCity"
              :validations="doctorCityValidation"
              :validation-message="doctorCityValidationMessage"
              :label="section.cityLabel"
            />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs">
            <form-multi-select
              id="doctorState"
              v-model="doctorStateValue"
              :label="section.stateLabel"
              :placeholder="chooseStateLabel"
              :validations="doctorStateValidation"
              :validation-message="doctorStateValidationMessage"
              :multiple="false"
              :allow-empty="false"
              :options="stateDropDownOptions"
              :searchable="true"
              :searchStart="true"
              :clear-on-select="true"
            />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs">
            <form-input
              id="zip"
              v-model="zipValue"
              :label="section.zipCodeLabel"
              :validations="doctorZipValidation"
              :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
              :validation-message="section.zipCodeValidationMessage"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs">
          </div>
          <div>
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorNameClick()"
              @keyup.enter="handleDoctorNameClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchDoctorNameLinkText }}
            </a>
            <a
              v-if="section.showSearchByPhone"
              tabindex="0"
              class="inline-caret-link reverse hide-print next-line"
              :style="globalTheme.colorPrimary"
              @click="handleSearchByDoctorPhoneNumberClick()"
              @keyup.enter="handleSearchByDoctorPhoneNumberClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchByPhoneLinkText }}
            </a>
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorClick()"
              @keyup.enter="handleDoctorClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchNpiLinkText }}
            </a>
          </div>
        </div>
        <div
          v-if="searchByDoctorLicense"
          grid="row wrap bottom"
        >
          <div
            column="xs-12 s-12 m-6"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="npiNumber"
              v-model="npiNumber"
              :validations="npiValidation"
              :validation-message="npiValidationMessage"
              :label="section.npiLabel"
              :maxLength="npimaxlength"
              :disabled="uniqueDisabledCheck('npiNumber')"
              @input="handleZipInput" />
            <div>
              <h5
                class="moon"
                style="margin-bottom:-20px;"
              >
                <strong>OR</strong>
              </h5>
            </div>
          </div>
          <div column="xs-12 s-12 m-6" />
          <div
            column="xs-12 s-12 m-6"
            :class="{'auth-user': hideNetworkDropdown}"
            class="_mbs">
            <form-input
              id="doctorLicense"
              v-model="doctorLicense"
              :label="section.doctorLicenseLabel"
              :validations="doctorlicenseValidation"         
              :maxLength="licensemaxlength"
              :validation-message="doctorLicenseValidationMessage"
              :disabled="uniqueDisabledCheck('doctorLicense')"
              @input="handleZipInput" />
          </div>
          <div
            column="xs-12 s-12 m-6" 
            class="_mbs zip-column"
            style="margin-bottom:32px;"
          >
            <form-input
              id="zip"
              v-model="zipValue"
              :label="section.zipCodeLabel"
              :validations="gettingCoords ? '' : 'required|regex:^[0-9]{5}(-[0-9]{4})?$'"
              :mask="(zipValue.length > 5) ? &quot;#####-####&quot; : &quot;#####&quot;"
              :hide-validation-message="coordsRequested"
              :validation-message="section.zipCodeValidationMessage"
              @input="handleZipInput" />
          </div>
          <div column="xs-12 s-12 m-6" >
            <a
              tabindex="0"
              class="inline-caret-link reverse hide-print"
              :style="globalTheme.colorPrimary"
              @click="handleDoctorNameClick()"
              @keyup.enter="handleDoctorNameClick()"
            >
              <font-awesome-icon
                :icon="['fas', 'caret-right']"
                class="caret _mrxxs" />
              {{ section.searchDoctorNameLinkText }}
            </a>
          </div>
        </div>
      </div>
      <!-- Submit Buttons-->
      <div
        v-if="!searchByDoctorAddress"
        column="xs-12 s-12 m-6 l-4" 
        class="_mbs submit-column" 
        :class="{'auth-user': hideNetworkDropdown, 'align-self-center' : !(geolocationAvailable && !framed)}">
        <div class="button-wrap">
          <button
            v-if="geolocationAvailable && !framed"
            :disabled="gettingCoords || submittingZip"
            :style="globalTheme.buttonSecondary"
            class="sm"
            type="button"
            @click.prevent="getUserCoords"
            @keydown.enter.prevent="getUserCoords"
          >
            <font-awesome-icon
              v-if="gettingCoords"
              icon="spinner"
              pulse
              aria-label="Loading..."
              class="_mrxs"
            />
            <target-svg
              v-else
              class="
              _mrxs"
            />
            {{ section.myLocationButton }}
          </button>
          <span
            v-if="geolocationAvailable && !framed"
            class="_mtxs _mbxs seperator" 
            :style="globalTheme.colorSecondary">{{section.orSeperator}}</span>
          <button
            class="sm"
            type="submit"
            :style="globalTheme.buttonSecondary"
            :disabled="submittingZip">
            {{section.searchButtonLabel}}
          </button>
        </div>
      </div>
      <div
        v-if="searchByDoctorAddress"
        column="xs-12 s-12 m-6 l-4" 
        class="_mbs submit-column align-self-center" >
        <div class="button-wrap">
          <button
            :disabled="submittingZip || !isSearchButtonDisabled"
            :style="globalTheme.buttonSecondary"
            class="sm"
            type="button"
            @click.prevent="handleDoctorAddressSearch"
            @keydown.enter.prevent="handleDoctorAddressSearch"
          >
            <font-awesome-icon
              v-if="submittingZip"
              icon="spinner"
              pulse
              aria-label="Loading..."
              class="_mrxs"
            />
            {{ section.searchByAddressButtonLabel }}
          </button>
        </div>
      </div>
    </form>
    <div
      grid="row wrap"
      class="additional-info-blocks">
      <div :column="section.infoContentAdditional ? 'xs-12 s-12 m-6 l-7' : 'xs-12 s-12'">
        <rich-text
          v-if="section.infoContent"
          class="bg-offwhite _pts _pbs _plm _prm content-block"
          :html="section.infoContent"
        />
      </div>
      <div
        v-if="section.infoContentAdditional" 
        column="xs-12 s-12 m-6 l-5">
        <rich-text
          class="bg-offwhite _pts _pbs _plm _prm content-block"
          :html="section.infoContentAdditional" />
      </div>
    </div>
  </ValidationObserver>
</template>
<script lang="ts">
   import {defineComponent} from 'vue'
  import Search from '@/mixins/Search'
  export default defineComponent({
    mixins: [Search],
    props: {
      framed: {
        required: true,
        type: Boolean
      }
    },
    data() {
      return {
        npiValidationMessage: 'Please enter a valid 10 Digit NPI Number',
        doctorLicenseValidationMessage: 'Please enter a valid Doctor License',
        doctorAddressValidationMessage: 'Please enter a valid Address',
        doctorCityValidationMessage: 'Please enter a valid city',
        doctorStateValidationMessage: 'Please Select a State',
        doctorPhoneNumberMask: '(###)###-####',
        npimaxlength:'10' as String,
        licensemaxlength:'20' as String,
      }
    },
    computed:{
      doctorlicenseValidation(){
      //  if((this.doctorLicense.length >= 1) && (this.doctorLicense.length < 20)) return 'required|regex:^.{1,20}$'
      },
      npiValidation(){
        if(this.npiNumber.length >= 1) return 'required|regex:^[0-9]{10}$'
      },
      doctorAddressValidation() {
        return 'required|address'
      },
      doctorCityValidation() {
        return 'required|city'
      },
      doctorStateValidation() {
        return (this.zipValue === null || this.zipValue === '') && (this.doctorStateValue === null) ? 'required' : ''
      },
      doctorZipValidation() {
        return this.doctorStateValue === null ? 'required|regex:^[0-9]{5}(-[0-9]{4})?$' : ''
      },
      doctorPhoneNumberValidation() {
        if(this.doctorPhoneNumber.length >= 1) return 'required|phoneNumber'
      },
      isDoctorAddressValid() {
        return this.doctorStreetaddress !== null && this.doctorCity !== null
      },
      isDoctorStateOrZipValid() {
        return this.doctorStateValue !== null || this.isZipCodeValid
      },
      isSearchButtonDisabled() {
        return this.isDoctorAddressValid && this.isDoctorStateOrZipValid
      }
    },
  })
</script>
<style scoped lang="scss">
.align-self-center {
  align-self: center;
}
.next-line {
  display: block;
}
</style>
